const AutocompleteArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  );
};

export default AutocompleteArrowIcon;
