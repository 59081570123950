import * as Yup from 'yup';
import { emailRegexp } from '#constants/regexp.ts';

// TODO: i18n support
const schema = Yup.object().shape({
  email: Yup.string()
    .required('The field is required')
    .email('Wrong email address')
    .matches(emailRegexp, 'Wrong email address')
    .max(254, 'The value must not exceed 254 characters')
    .trim(),
});

export default schema;
