import * as Yup from 'yup';
import { onlyLatinRegexp, onlyLatinWithNumbersAndFewSymbolsRegexp } from '#constants/regexp.ts';
import { toDefaultState } from '#schemas/common.ts';
import { STATES_FULL_NAMES } from '#constants/states.ts';

// TODO: i18n support
const schema = Yup.object().shape({
  first_name: Yup.string()
    .required('Field is required')
    .matches(onlyLatinRegexp, '*Please, use letters only (a-z) in this field')
    .min(2, 'The value must be at least 2 characters')
    .max(32, 'The value must not exceed 32 characters')
    .trim(),
  last_name: Yup.string()
    .required('Field is required')
    .matches(onlyLatinRegexp, '*Please, use letters only (a-z) in this field')
    .min(2, 'The value must be at least 2 characters')
    .max(32, 'The value must not exceed 32 characters')
    .trim(),
  state: Yup.string().transform(toDefaultState).oneOf(STATES_FULL_NAMES, 'Select a valid state'),
});

export default schema;
