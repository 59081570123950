import { useState, forwardRef, type ForwardedRef, type ChangeEvent, useRef } from 'react';
import styles from './Autocomplete.module.scss';
import Input from '#components/shared/ui/Input';
import useOutsideClick from '#hooks/useOutsideClick.ts';
import { contains } from '#utils/string.ts';
import AutocompleteArrowIcon from '#components/shared/ui/Icon/AutocompleteArrow.tsx';

type Props = {
  data: string[];

  value: string;

  name?: string;
  error?: string;
  className?: string;
  placeholder?: string;

  initialOpen?: boolean;
  showOptionsOnEmpty?: boolean;

  onValueChanged: (value: string) => void;
};

const Autocomplete = (
  {
    name,
    className,
    data,
    value,
    error,
    placeholder,
    initialOpen,
    onValueChanged,
    showOptionsOnEmpty,
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const [options, setOptions] = useState<string[]>(data);
  const [open, setOpen] = useState<boolean>(initialOpen ?? false);

  const autocompleteClassName = `${styles.autocomplete} ${className ?? ''}`.trim();

  const valueChangedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setOptions(() => {
      if (!value) return data;
      const options = data.filter((option) => contains(option, value));
      if (options.length) return options;
      return showOptionsOnEmpty ? data : [];
    });
    onValueChanged(value);
  };

  const selectItemHandler = (item: string) => {
    setOpen(false);
    setOptions(data);
    onValueChanged(item);
  };

  const withOpenClass = (classes: string) => {
    return `${open ? styles.open : ''} ${classes}`;
  };

  useOutsideClick(autocompleteRef, () => setOpen(false));

  return (
    <div ref={autocompleteRef} className={autocompleteClassName}>
      <Input
        name={name}
        ref={ref}
        error={error}
        value={value}
        placeholder={placeholder}
        onChange={valueChangedHandler}
        onClick={() => setOpen((prev) => !prev)}
        style={{ textOverflow: 'ellipsis' }}
      />
      <div className={styles.autocompleteButtonContainer}>
        <button
          className={withOpenClass(styles.autocompleteButton)}
          onClick={() => setOpen((prev) => !prev)}
          type="button"
          aria-label="Open"
          title="Open"
        >
          <AutocompleteArrowIcon />
          <span className={styles.autocompleteRipplen}></span>
        </button>
      </div>
      {open ? (
        <ul className={styles.list}>
          {options.map((option) => {
            return (
              <li className={styles.option} key={option} onClick={() => selectItemHandler(option)}>
                {option}
              </li>
            );
          })}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
};

export default forwardRef(Autocomplete);
