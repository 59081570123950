import * as Yup from 'yup';
import { onlyLatinRegexp } from '#constants/regexp.ts';
import { STATES_FULL_NAMES } from '#constants/states.ts';
import { toDefaultState } from '#schemas/common.ts';

// TODO: i18n support
const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('Field is required')
    .matches(onlyLatinRegexp, '*Please, use letters only (a-z) in this field')
    .min(2, 'The value must be at least 2 characters')
    .max(32, 'The value must not exceed 32 characters')
    .trim(),
  lastName: Yup.string()
    .required('Field is required')
    .matches(onlyLatinRegexp, '*Please, use letters only (a-z) in this field')
    .min(3, 'The value must be at least 3 characters')
    .max(32, 'The value must not exceed 32 characters')
    .trim(),
  state: Yup.string().transform(toDefaultState).oneOf(STATES_FULL_NAMES, 'Select a valid state'),
});

export default schema;
