import { type BaseSyntheticEvent, type FC } from 'react';
import schema from '#schemas/address.ts';
import { Controller, type FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './FeatureHeroFormAddress.module.scss';
import Input from '#components/shared/ui/Input';
import Button from '#components/shared/ui/Button';
import Form from '#components/shared/ui/Form';
import { STATES_FULL_NAMES, STATES_NAMES_CODES_MAP } from '#constants/states.ts';
import Autocomplete from '#components/shared/ui/Autocomplete';
import { urlBuilder } from '#utils/urlBuilder.ts';
import analytics from '#utils/analytics.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import { anim2Url } from '#constants/testPage.ts';

type FormValues = {
  address: string;
  city: string;
  state?: string;
};

const FeatureHeroFormAddress: FC<FeatureHeroCommonProps> = ({ t, isNsr, isDc }) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { address: '', city: '', state: '' },
  });
  const { handleSubmit, formState, register, setValue } = form;

  const onSubmit = (values: FormValues) => {
    analytics.event({
      action: 'search_address',
      event_params: `street_value: ${values.address}, city_value: ${values.city}, state_value: ${values.state}`,
    });
    const isTestAnim = window.location.pathname.includes(anim2Url);
    const url = urlBuilder({
      state: STATES_NAMES_CODES_MAP[values.state ?? 'All'],
      cityName: values.city,
      address: values.address,
      ...(isNsr && { is_nsr: 'true' }),
      ...(isTestAnim && { isAnim2: 'true' }),
    });

    window.location.assign(url);
  };

  const onFail = (errors: FieldErrors<FormValues>, e?: BaseSyntheticEvent) => {
    if (errors.state) setValue('state', '');
  };

  return (
    <Form
      className={`${styles.form} ${isDc && styles.dc}`}
      form={form}
      onSubmit={handleSubmit(onSubmit, onFail)}
    >
      <Input
        {...register('address')}
        placeholder="Street"
        wrapperClassName={styles.item}
        error={formState.errors.address?.message}
      />
      <Input
        {...register('city')}
        placeholder="City"
        wrapperClassName={styles.item}
        error={formState.errors.city?.message}
      />
      <Controller
        name="state"
        control={form.control}
        render={({ field: { ref, value, onChange, name }, formState }) => (
          <Autocomplete
            name={name}
            ref={ref}
            value={value ?? ''}
            placeholder="State"
            className={styles.item}
            data={STATES_FULL_NAMES}
            error={formState.errors.state?.message}
            onValueChanged={(value) => onChange(value)}
            showOptionsOnEmpty
          />
        )}
      />
      <Button variant="accent" size="lg" className={styles.button}>
        {t.lookups.address.action}
      </Button>
    </Form>
  );
};

export default FeatureHeroFormAddress;
