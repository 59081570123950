import * as Yup from 'yup';
import { phoneNumberRegexp } from '#constants/regexp.ts';

// TODO: i18n support
const schema = Yup.object().shape({
  phone: Yup.string()
    .required('The field is required')
    .matches(phoneNumberRegexp, 'The value must be phone number')
    .trim(),
});

export default schema;
