import { type FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './FeatureHeroFormEmail.module.scss';
import Form from '#components/shared/ui/Form';
import Input from '#components/shared/ui/Input';
import Button from '#components/shared/ui/Button';
import schema from '#schemas/email.ts';
import { urlBuilder } from '#utils/urlBuilder.ts';
import analytics from '#utils/analytics.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import { anim2Url } from '#constants/testPage.ts';

type FormValues = {
  email: string;
};

const FeatureHeroFormEmail: FC<FeatureHeroCommonProps> = ({ t, isNsr, isDc }) => {
  const form = useForm<FormValues>({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, formState, register } = form;

  const onSubmit = (values: FormValues) => {
    analytics.event({
      action: 'search_email',
      event_params: `email_value: ${values.email}`,
    });
    const isTestAnim = window.location.pathname.includes(anim2Url);
    const url = urlBuilder({
      email: values.email,
      ...(isNsr && { is_nsr: 'true' }),
      ...(isTestAnim && { isAnim2: 'true' }),
    });

    window.location.assign(url);
  };

  return (
    <Form
      className={`${styles.form} ${isDc && styles.dc}`}
      form={form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        {...register('email')}
        type="email"
        placeholder="Type someone's email"
        wrapperClassName={styles.input}
        error={formState.errors.email?.message}
      />
      <Button variant="accent" size="lg" className={styles.button}>
        {t.lookups.email.action}
      </Button>
    </Form>
  );
};

export default FeatureHeroFormEmail;
