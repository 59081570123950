export enum FeatureHeroTypes {
  nameLookup = 'name',
  emailLookup = 'email',
  addressLookup = 'address',
  phoneLookup = 'phone',
  vin = 'vin',
  backgroundCheck = 'backgroundCheck',
  phoneSearch = 'phoneSearch',
  phoneSearch1 = 'phoneSearch1',
}
