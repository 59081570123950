import * as Yup from 'yup';
import {
  onlyLatinWithNumbersAndFewSymbolsRegexp,
  onlyLatinWithNumbersAndManySymbolsRegexp,
} from '#constants/regexp.ts';
import { toDefaultState } from '#schemas/common.ts';
import { STATES_FULL_NAMES } from '#constants/states.ts';

// TODO: i18n support
const schema = Yup.object().shape({
  address: Yup.string()
    .required('Field is required')
    .matches(onlyLatinWithNumbersAndManySymbolsRegexp, 'The value is invalid')
    .min(2, 'The value must be at least 2 characters')
    .max(64, 'The value must not exceed 64 characters')
    .uppercase()
    .trim(),
  city: Yup.string()
    .required('Field is required')
    .matches(
      onlyLatinWithNumbersAndFewSymbolsRegexp,
      'The value must contain only latin letters, numbers, periods and hyphens'
    )
    .min(2, 'The value must be at least 2 characters')
    .max(32, 'The value must not exceed 32 characters')
    .uppercase()
    .trim(),
  state: Yup.string().transform(toDefaultState).oneOf(STATES_FULL_NAMES, 'Select a valid state'),
});

export default schema;
